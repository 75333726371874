import { Popover } from '@headlessui/react'
import { Listbox } from '@headlessui/react'
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function Filter(props){

    const { searchParams,getFilterselected,getFilter, selectedStatus,
        selectedType, setSearchParams,
        setSelectedStatus,
        setSelectedType,date,setDate,toDate,setToDate,
        sendToDate,setsendToDate,sendFromDate,setsendFromDate}=props;

    const status = [
        { status: 'Paid' },
        { status: 'Unpaid' },
        { status: 'Requested' },
      
    ]
    const type = [
        { type: 'Stripe' },
        { type: 'Paypal' },
      
    ]


    
    const [dateError, setDateError]=useState({fromDate: false, toDate: false})
    const [open, setOpen]=useState(false);
    const [validDate, setValidDate]=useState(true);

    /**
     * The filterHandler function checks if the selected dates are valid and calls the getFilter
     * function with the selected dates as parameters.
     * @returns nothing (undefined).
     */
    const filterHandler=()=>{
        const dateFrom = new Date(sendFromDate);
        const dateTo = new Date(sendToDate);
        if(dateFrom.getTime() > dateTo.getTime()){
            setValidDate(false);
            return;
        }
        else if(date!=='' && toDate===''){
            setDateError({fromDate: false, toDate: true})
            return;
        }else if(date==='' && toDate!==''){
            setDateError({fromDate: true, toDate: false})
            return;
        }
        else{
            setValidDate(true);
        }
        getFilter( selectedStatus,selectedType, sendFromDate, sendToDate);
        setOpen(false);
    }

    const formatDates = (inputDate) => {
        const date = new Date(inputDate * 1000);
       
        // const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate
       
    };


    const onFilterModalClose=()=>{
        setOpen(false)
        setDateError({fromDate: false, toDate: false})
        setValidDate(true);
        if(searchParams.get('start_date')!==null){
            setDate(formatDates(Number(searchParams.get('start_date'))))
            setToDate(formatDates(Number(searchParams.get('end_date'))))
        }else{
            setDate('')
            setsendFromDate('')
            setsendToDate('')
            setToDate('')
        }
        if(searchParams.get('payment_status')==null){
            setSelectedStatus('')
        }
        if(searchParams.get('payment_type')==null){
            setSelectedType('')
        }
       
    }

    /**
     * The function `formatDate` takes a date object as input and returns a formatted string
     * representation of the date in the format "day month year".
     * @returns The function `formatDate` returns a formatted date string in the format "day month
     * year".
     */
    const formatDate = (date) => {
        if (!date) return '';
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    /**
     * The function `sendFormat` takes a date object as input and returns a formatted string in the
     * format "YYYY-MM-DD".
     * @returns The function `sendFormat` returns a formatted date string in the format "YYYY-MM-DD".
     */
    const sendFormat = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(()=>{
        if(searchParams.get('start_date')!==null){
            const startdate = new Date(searchParams.get('start_date')* 1000);
            const enddate = new Date(searchParams.get('end_date')* 1000);
            setsendFromDate(sendFormat(startdate))
            setsendToDate(sendFormat(enddate))
        }
    },[])

    const changeToDate=(date)=>{
        setToDate(formatDate(date)); 
        setsendToDate(sendFormat(date));
    }

    const changeFromDate=(date)=>{
        setDate(formatDate(date)); 
        setsendFromDate(sendFormat(date))
    }

    return(
        <div className='relative'>
            <div className="relative mr-2.5 filter-btn cursor-pointer" onClick={()=>setOpen(!open)}>
                <button id='add-filter' data-testid="filter"
                    className="text-[14px] sm:block hidden relative text-[#52525A] border w-[83px] h-[32px] 
                        border-[#A1A1A9] rounded-[6px] leading-normal font-medium pr-[20px] pl-[36px] hover:bg-[#EFF1F8]"
                >
                    Filter
                </button>
                <button className="text-[14px] sm:hidden block relative text-[#52525A] border w-[35px] h-[32px] 
                        border-[#A1A1A9] rounded-[6px] leading-normal  font-medium">
                </button>
                <img src="/images/filter-icon.svg" alt="filter-icon" className="absolute top-[10px] left-2.5" />
            </div>
            {open && <>
                <div className="absolute top-50 right-0 z-10 filter-content">
                    <div className="bg-[#FFF] shadow-bs3 w-[238px] h-[435px] rounded-[10px]">
                        <div className="px-[19px] py-[15px]">
                            <div className="border-b border-[#DEDEDE] pb-4">
                                <p className="text-[13px] leading-normal pb-1 font-medium text-[#343434]">
                                    Payment Status</p>
                                <Listbox as='div' id="payment-status" 
                                    className="border flex relative border-[#A1A1A9] h-11 py-2.5
                                            focus:border-[#343434]" value={selectedStatus} onChange={setSelectedStatus}>
                                    <Listbox.Button
                                        className="text-[14px] z-2 px-3 w-full  text-start outline-none font-medium 
                                                leading-normal text-[#A1A1A9]">
                                        <span className={` ${selectedStatus=="" ? 'text-[#A1A1A9]' : 'text-[#4A4A4A]'} `} 
                                            data-testid="filter-status">
                                            {selectedStatus==='' ? 'Status' : selectedStatus.status}
                                            <img id='auction-status' src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                                className="absolute cursor-pointer top-[19px] right-4" />
                                        </span>
                                    </Listbox.Button>
                                    <Listbox.Options className="bg-white rounded-[8px] pr-[15px] py-1 z-[1000] 
                                            top-[45px] absolute w-full [&[data-te-dropdown-show]]:block 
                                            shadow-bs1" >
                                        <ul className="pl-[18px] pr-[15px] py-2 h-[122px] overflow-y-auto 
                                                overflow-x-hidden customScrollbar  text-[12px] leading-normal font-medium 
                                                text-[#4A4A4A]">
                                            {status.map((status,index)=>
                                                <Listbox.Option key={index} value={status}
                                                    className="py-2"> <span className="cursor-pointer">
                                                        {status.status}</span>
                                                </Listbox.Option>
                                            )}
                                        </ul>
                                    </Listbox.Options>
                                </Listbox>
                            </div>
                            <div className="border-b border-[#DEDEDE] pb-4 pt-2">
                                <p className="text-[13px] leading-normal pb-1 font-medium text-[#343434]">
                                    Payment Type</p>
                                <Listbox as='div' className="border flex relative border-[#A1A1A9] h-11 py-2.5 
                                            focus:border-[#343434]" value={selectedType} onChange={setSelectedType}>
                                    <Listbox.Button
                                        className="text-[14px] z-2 px-3 w-full  text-start outline-none font-medium 
                                                leading-normal text-[#A1A1A9]">
                                        <span className={` ${selectedType=="" ? 'text-[#A1A1A9]' : 'text-[#4A4A4A]'} `}  
                                            data-testid="filter-status">
                                            {selectedType==='' ? 'Payment Type' : selectedType.type}
                                            <img id='auction-status' src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                                className="absolute cursor-pointer top-[19px] right-4" />
                                        </span>
                                    </Listbox.Button>
                                    <Listbox.Options className="bg-white rounded-[8px] pr-[15px] py-1 z-[1000] 
                                            top-[45px] absolute w-full [&[data-te-dropdown-show]]:block 
                                            shadow-bs1" >
                                        <ul className="pl-[18px] pr-[15px] py-2 h-[85px] overflow-y-auto 
                                                overflow-x-hidden customScrollbar  text-[12px] leading-normal font-medium 
                                                text-[#4A4A4A]">
                                            {type.map((type,index)=>
                                                <Listbox.Option key={index} value={type}
                                                    className="py-2"> <span className="cursor-pointer">
                                                        {type.type}</span>
                                                </Listbox.Option>
                                            )}
                                        </ul>
                                    </Listbox.Options>
                                </Listbox>
                            </div>
                        
                            <div className="pt-2">
                                <p className="text-[13px] leading-normal pb-1 font-medium text-[#343434]">From</p>
                        
                                <DatePicker
                                    // selected={date}

                                    focusSelectedMonth={true}
                                    onChange={(date) => {changeFromDate(date); setDateError({fromDate: false, toDate: false})}}
                                    customInput={
                                        <div className="border flex relative border-[#A1A1A9] mx-1  h-11 py-2.5 
                                            focus:border-[#343434]">
                                            <input type="text" id="from_date"
                                                className=" text-[14px] z-2 pl-3 cursor-pointer pr-7 w-full text-start 
                                                outline-none font-medium leading-normal text-[#343434] placeholder:text-[#A1A1A9]"
                                                value={date} placeholder='Select date' />
                                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                                className="absolute cursor-pointer top-[19px]  right-4" />
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 
                                            pointer-events-none">
                                            </div>
                                        </div>
                                    }
                                />
                                {dateError.fromDate && <p className="text-[10px] font-medium leading-normal pt-1 date-err 
                                text-[#984848]">
                                    Enter both start and end date</p>}
                            </div>
                       
                            <div className=" pt-3 pb-4">
                                <p className="text-[13px] leading-normal to-date pb-1 font-medium text-[#343434]">
                                    To</p>
                            
                                <DatePicker
                                    // selected={date}
                                    onChange={(toDate) => {changeToDate(toDate); setValidDate(true);
                                        setDateError({fromDate: false, toDate: false})}}
                                    customInput={
                                        <div className="border cursor-pointer flex relative border-[#A1A1A9]  border-date mx-1
                                          
                                            h-11 py-2.5 focus:border-[#343434]" data-te-dropdown-ref>
                                            <input type="text" className=" text-[14px] z-2 
                                        pl-3 pr-7 w-full  text-start outline-none font-medium leading-normal text-[#343434] 
                                        placeholder:text-[#A1A1A9]" value={toDate} placeholder='Select date' />
                                            <img src="/images/dropdown-icon.png" alt="dropdown-icon" 
                                                className="absolute cursor-pointer top-[19px]  right-4" />
                                            <div className="absolute inset-y-0 left-0 flex items-center 
                                                    pl-3.5 pointer-events-none">
                                            </div> 
                                        </div>
                                    }
                                />
                                {!validDate && <p className="text-[10px] font-medium leading-normal pt-1 date-err 
                                text-[#984848]">
                                    End date is before the start date</p>}
                                {dateError.toDate && <p className="text-[10px] font-medium leading-normal pt-1 date-err 
                                text-[#984848]">
                                    Enter both start and end date</p>}
                            </div>  
                            <button id='apply-filter' data-testid="apply-filter-btn"
                                type='submit' className="px-[31px] apply-filter-btn py-[12px] bg-[#282828] w-full 
                            text-[#FFFFFF] leading-normal font-medium text-[14px] rounded-[6px] hover:bg-[#626465]" 
                                onClick={filterHandler}>
                                Apply filter
                            </button>
                        </div>
                    </div>
                
                </div>
                <div className="fixed left-0 top-0 z-[5] h-full w-full overflow-y-auto overflow-x-hidden 
                outline-none bg-opacity-75 transition-opacity" onClick={onFilterModalClose}/>
            </>}
        </div>
    );
}