/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Oval } from  'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config/index';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

export default function UnPublishAuctionModal({unpublishModalOpen, setUnpublishModalOpen, handleUnpublishAuction, loader, noLot,loading,
    selectedId, auctionData, lotInfo, isRequiredFieldEmpty, setIsRequiredFieldEmpty, isUnPublished, setIsUnPublished, domain, notConnect}) {
    const Navigate=useNavigate();
    const [startDate,setStartDate] = useState('')
    const [startMin,setStartMin] = useState('')
    const [endDate,setEndDate] = useState('')
    const [endMin,setEndMin] = useState('')
    const [end,setEnd] = useState(false);
    
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to format the start and end dates of an auction and set them in the
    component's state variables (`startDate`, `startMin`, `endDate`, `endMin`). */
    useEffect(()=>{
        const isSafari = navigator.userAgentData && navigator.userAgentData.brands.some(brand => brand.brand === 'Safari');

        const start_date = new Date(auctionData?.start_date);
        const end_date = new Date(auctionData?.end_date)

        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            timeZoneName: "short"
        };
        const formatter = new Intl.DateTimeFormat("en-GB", options);
        const formattedDate = formatter.format(start_date);
        const formattedEndDate = formatter.format(end_date);
        const [date1, time] = formattedDate.includes('at') ? formattedDate.split("at ") : formattedDate.split(", ");
        const [date2, time1] = formattedEndDate.includes('at') ? formattedEndDate.split("at ") : formattedEndDate.split(", ");
        setStartDate(date1.includes('1970')?'':date1)
        setStartMin(time)
        setEndDate(date2.includes('1970')?'':date2)
        setEndMin(time1)
    },[])

    const calculateTimeRemaining = () => {
        const now = new Date();
        let timeDifference = new Date(auctionData?.start_date) - now;
        if (timeDifference <= 0) {
            timeDifference = new Date(auctionData?.end_date) - now;
            setEnd(true);
            if(timeDifference <= 0){
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        }
    
        const pad = (num) => String(num).padStart(2, "0");
        const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
        return { days, hours, minutes, seconds };
    };
    
    const [timeRemaining, setTimeRemaining] = useState();
    const [copied, setCopied] = useState(false);

    const handleCopyLink = () => {
        // Copy the auction URL to the clipboard
        navigator.clipboard.writeText(`https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false)
                }, 800);
            })
            .catch((error) => {
                console.error('Failed to copy URL: ', error);
            });
    };
    const shareOnSocialMedia = (type) => {
        // Define the URL and content you want to share
        const shareURL = `https://${domain}.${config.Environment.domain}/auctions/${auctionData._id}`; // URL to be shared
        const shareText = 'Check out this live auction!'; // Custom sharing text
        const shareTitle = 'Live Auction Details'; // Custom sharing title
        if(type=='facebook'){        
            const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareURL)}&quote=${encodeURIComponent(shareText)}`;
            window.open(facebookShareURL, '_blank');
        }
        else if(type=='linkedin'){
            const linkedInShareURL = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(shareTitle)}&summary=${encodeURIComponent(shareText)}`;
            window.open(linkedInShareURL, '_blank');
        }
        else{
            const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareURL)}`;
            window.open(twitterShareURL, '_blank');
        }
    };
    useEffect(() => {
        let timer;
        if(isUnPublished){
            timer = setInterval(() => {
                setTimeRemaining(calculateTimeRemaining());
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isUnPublished]);

    return (
        <Transition appear show={unpublishModalOpen|| isRequiredFieldEmpty || isUnPublished}  as={Fragment} data-testid="confirm-unpublish">
            <Dialog as="div" className="relative z-[99]"  onClose={()=>setUnpublishModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80" />
                </Transition.Child>
                <div className="fixed left-0 top-0 z-[1055] h-full w-full 
                    overflow-y-auto overflow-x-hidden outline-none">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl 
                                    bg-white p-6 text-left align-middle shadow-xl transition-all 
                                    min-[640px]:mx-auto min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] 
                                    min-[640px]:max-w-[520px]">
                                <button
                                    type="button" id='close-icon'
                                    className="box-content rounded-none border-none absolute top-4 right-4 z-10"
                                    data-te-modal-dismiss
                                    aria-label="Close" onClick={()=>setUnpublishModalOpen(false)}>
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img" loading="lazy"/>
                                </button>
                                <div className="relative pt-[42px] pb-[15px]">
                                    <p className="px-4 text-center pb-[31px] leading-normal text-[16px] 
                                    text-[#343434] font-medium">Are you sure you want to unpublish this auction?</p>
                                    <div className="flex justify-center">
                                        <button data-testid="cancel-unpublish" 
                                            className="px-[31px] py-[12px] border border-[#A1A1A9] 
                                            bg-[#fffff]  text-[#282828] leading-normal 
                                            font-medium text-[14px] rounded-[6px]" onClick={()=>setUnpublishModalOpen(false)}>
                                            Cancel
                                        </button>
                                        <a className='ml-[20px]'>
                                            <button data-testid="unpublish"
                                                disabled={loader}
                                                className="px-[31px] py-[12px] bg-[#282828]  text-[#FFFFFF] 
                                                leading-normal font-medium text-[14px] rounded-[6px] flex justify-center 
                                                disabled:bg-[#D4D4D8]" 
                                                onClick={()=>{ handleUnpublishAuction(selectedId)}}>
                                                Unpublish
                                                {loader&& 
                                                <Oval
                                                    height={20}
                                                    width={50}
                                                    color="#FFFFFF"
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#000000"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />
                                                }
                                            </button>
                                        </a>
                                        
                                        
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}