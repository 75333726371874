import { configureStore } from '@reduxjs/toolkit';
import registrationSlice from '../pages/auth/registrationSlice';
import loginSlice from '../pages/auth/loginSlice';
import profileSlice from '../pages/account/profileSlice';
import pricingSlice from '../pages/Pricing/pricingSlice';
import bidsSlice from '../pages/Auction/bidsSlice';
import nameSlice from '../pages/account/nameSlice';
import socketSlice from '../pages/Auction/auctionView/socketSlice';
import auctionSlice from '../pages/Auction/auctionView/auctionSlice';

const store = configureStore({
    reducer: {
        registration: registrationSlice,
        login: loginSlice,
        profile: profileSlice,
        pricingSlice: pricingSlice,
        bidsSlice: bidsSlice,
        name : nameSlice,
        socket: socketSlice,
        auction: auctionSlice
    },
});

export default store;