/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { extenstionType } from '../../../../../CommonComponent/staticValues';
import { dataService } from '../../../../../services/dataService';
import { registrationType } from '../../../../../CommonComponent/staticValues';
import { buyerFees } from '../../../../../CommonComponent/staticValues';
import { currencySymbolMapping } from '../../../../../CommonComponent/staticValues';
import ToolTip from '../../../../../CommonComponent/tooltip'
import { Tooltip } from 'react-tippy';
import { useLocation } from 'react-router-dom';
import disableFieldsBasedOnAuctionStatus from '../../../disableFieldsBasedOnAuctionStatus';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export default function SetUpAuctionRules({auctionData,setAuctionData, setEditedAuctionData}) {
    const [profile, setProfile] = useState({})
    const state = useLocation();
    const user = (localStorage.getItem('user'));
    const [extensionTypeShow, setExtensionTypeShow] = useState(state.state===null?false:true)
    const [registrationTypeShow, setRegistrationTypeShow] = useState(state.state===null?false:true)
    const [buyerFeesTypeShow, setBuyerFeesTypeShow] = useState(state.state===null?false:true)
    const [showFullContent, setShowFullContent] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [errorMessages, setErrorMessages] = useState();
    const [isManual,setIsManual] = useState('');
    const [isCardValid,setIsCardValid] = useState('');

    const plan_status = profile.plan_type;
    const ldClient = useLDClient();
    


    /* The above code is defining a function called `fetchHandler` using the `useCallback` hook in
    React. This function is an asynchronous function that sends a request to a `dataService` to view
    a user's profile. It creates a `body` object with the user's email and then calls the
    `ViewProfile` method of the `dataService` with the `body` object as a parameter. The response
    from the `ViewProfile` method is then used to update the `profile` state using the `setProfile`
    function. The `fetchHandler` function does not have any dependencies */
    const fetchHandler = useCallback(async()=>{
        const body = {
            "email": user
        }
        const response = await dataService.ViewProfile(body);
        setProfile(response.data.data.data);
    },[])

    useEffect(()=>{
        fetchHandler()
    },[fetchHandler])

    /**
     * The function `handleBuyerFeeValueChange` is used to handle changes in the buyer fee value in a
     * React component, ensuring that the value is valid and updating the state accordingly.
     */
    const handleBuyerFeeValueChange = (e, field) => {
        e.preventDefault();
        const newValue = e.target.value;
        let digitsOnly = newValue.replace(/^0*(\d*)/, '$1').replace(/\D/g, '');
        if(field==='percentage'){
            if (digitsOnly!==''&&!isNaN(digitsOnly)) {
                digitsOnly = parseFloat(digitsOnly);
                digitsOnly = Math.min(100, Math.max(0, digitsOnly));  
            }  
        }
        if(field==='extension_time_between_lots'){
            const enteredValue = e.target.value.trim(); 
            const newValue = parseInt(enteredValue);
            if (!isNaN(newValue)) {
                // Check if the entered value is within the range 1 to 60
                if (newValue >= 1 && newValue <= 60) {
                    // If it is within the range, update the state with the new value
                    setAuctionData({
                        ...auctionData,
                        [field]: newValue
                    });
                    setErrorMessage("");
                } else if (newValue > 60) {
                    // If the entered value is greater than 60, set it to the maximum allowed value (60)
                    setErrorMessage('The max value allowed is 60');

                }
            } 
        }
        if(field==='extension_time'){
            const enteredValue = e.target.value.trim(); 
            const newValue = parseInt(enteredValue);
            if (!isNaN(newValue)) {
                // Check if the entered value is within the range 1 to 60
                if (newValue >= 1 && newValue <= 1440) {
                    // If it is within the range, update the state with the new value
                    setAuctionData({
                        ...auctionData,
                        [field]: newValue
                    });
                    setErrorMessages("");
                } else if (newValue > 1440) {
                    // If the entered value is greater than 60, set it to the maximum allowed value (60)
                    setErrorMessages('The max value allowed is 1440');

                }
            } 
        }
        const updatedData = {
            ...auctionData,
            [field]: digitsOnly,
        };
        setAuctionData(updatedData)
        setEditedAuctionData((prevData)=>({...prevData, [field]: digitsOnly}));

        if (field === 'percentage') {
            setAuctionData((prevData)=>({...prevData, fees: ''}));
            setEditedAuctionData((prevData)=>({...prevData, fees: ''}));
        }
        if(field==='fees'){
            setAuctionData((prevData)=>({...prevData, percentage: ''}));
            setEditedAuctionData((prevData)=>({...prevData, percentage: ''}));
        }
    }    

    const content=(<div>
        <p>
            Choose between three ways to end your auction. 
            All Lots - All lots close at the same set time. 
        </p>
        {showFullContent ? (
            <button className='text-left' onClick={() => setShowFullContent(false)}>
                As bidding is extended on all lots, 
                underbidders can bid again on any lot in the auction so this style of closing is useful 
                for auctions where bidders may be interested in more than one lot.
                Cascade - Each lot closes at pre-set intervals only after the previous lot has closed. 
                If a bid is made during the last minute of a lot closing, the auction for that lot will extend. 
                Since lots close consecutively, a bidding extension will delay the closing of the next lot, 
                ensuring only one lot closes at a time.
                Each lot closes at a specified closing time, two minutes apart. A bid placed within the last 
                minute of a lot closing will only extend the bidding on that lot. The added time will not affect 
                the closing time of any other lots, so the lots may close out of numerical order. This style of 
                closing is ideal for large auctions with over 100 lots as bidding extensions apply only to lots 
                with active bidders. Show Less...
            </button>
        ) : (
            <button onClick={() => setShowFullContent(true)}>Show More...</button>
        )}
    </div>
    );
    /**
     * Function to return the disabled state 
     * from disabled fields maping based on it's
     * auction status
     * @param {*} field 
     * @returns 
     */
    const returnDisableState = (field)=>{
        let statusCheck = false;
        let status = auctionData?.status ? auctionData.status : "";
        if(status)
            status = status !== "Accepting bids" ? status.toLowerCase() : "acceptingBids"
        switch(field) {
        case "extension_time_between_lots": {
            statusCheck = disableFieldsBasedOnAuctionStatus[status]?.extension_time_between_lots;
            break;
        }
        case "extension_time": {
            statusCheck = disableFieldsBasedOnAuctionStatus[status]?.extension_time;
            break;
        }
        case "extension_type": {
            statusCheck = disableFieldsBasedOnAuctionStatus[status]?.extension_type;
            break;
        }
        case "registration_type": {
            statusCheck = disableFieldsBasedOnAuctionStatus[status]?.registration_type;
            break;
        }
        case "add_buyer_fees": {
            statusCheck = disableFieldsBasedOnAuctionStatus[status]?.add_buyer_fees;
            break;
        }
        default:
            break;
        }
        return statusCheck;
    }


    function handleApprove(plan_status) {
        return new Promise(async (resolve) => {
            const context = {
                kind: 'user',
                key: 'plan',
                plan: plan_status,
            };

            // Identify the user context to set specific targeting
            await ldClient.identify(context);
            // Wait for initialization to ensure context is loaded
            await ldClient.waitForInitialization();

            // Retrieve the flag variation and resolve the promise with the result
            const disableLoginFlag = ldClient.variation('manual-approval', false);
            setIsManual(disableLoginFlag);
            resolve(disableLoginFlag);  // Explicitly resolve the flag value
        });
    }

    function handleApproveCredit(plan_status) {
        return new Promise(async (resolve) => {
            const context = {
                kind: 'user',
                key: 'plan',
                plan: plan_status,
            };

            // Identify the user context to set specific targeting
            await ldClient.identify(context);
            // Wait for initialization to ensure context is loaded
            await ldClient.waitForInitialization();

            // Retrieve the flag variation and resolve the promise with the result
            const disableLoginFlag = ldClient.variation('CreditCardValidation', false);
            setIsCardValid(disableLoginFlag);
            resolve(disableLoginFlag);  // Explicitly resolve the flag value
        });
    }

    useEffect(() => {
        handleApprove(plan_status);
        handleApproveCredit(plan_status);
    }, [plan_status, handleApprove,handleApproveCredit]); // Include both dependencies

  
    return (
        <div className="auction-rule-content">
            {/* <!-- dropdown for extension type--> */}
            <Listbox as="div" disabled={returnDisableState("extension_type")}  
                className="relative w-full" value={auctionData.extension_type} 
                onChange={(value) => {setExtensionTypeShow(true)
                    setAuctionData(prevData=>({...prevData,extension_type: value.name}))
                    setEditedAuctionData(prevData=>({...prevData,extension_type: value.name}))
                    if(value.name=="All Lots"){
                        setAuctionData((prevData)=>({...prevData, extension_time_between_lots: ''}));
                        setEditedAuctionData((prevData)=>({...prevData, extension_time_between_lots: ''}));
                    }
                }}>
                <div className="flex mt-4">
                    <p className="text-[12px] font-medium leading-normal text-[#343434]">Extension type</p>
                    <Tooltip
                        html={content}
                        position="bottom"
                        trigger="click"
                        interactive={true}
                        arrow="true"
                        arrowSize="regular"
                    >
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </Tooltip>
                </div>
                <Listbox.Button data-testid='extension-type' type="button" 
                    className={`text-[14px] text-start relative w-full h-11 px-3 border 
                    disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                border-[#A1A1A9] ${extensionTypeShow?'text-[#343434]':'text-[#A1A1A9]'} 
                outline-none font-medium leading-normal`} 
                    id="extension-type"
                    data-te-dropdown-toggle-ref
                    aria-expanded="false"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    {auctionData.extension_type===''?'Select':auctionData.extension_type}</Listbox.Button>
                <img src="/images/dropdown-icon.png" alt="dropdown-icon" className="absolute top-[40px] right-[15px]" 
                    loading="lazy" />
                <Listbox.Options 
                    className="bg-white rounded-[8px] z-30  absolute w-full [&[data-te-dropdown-show]]:block 
                shadow-bs1 list-none" aria-labelledby="extension-type">
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className=" pr-[15px] py-1 h-[120px] customScrollbar 
                                overflow-y-auto overflow-x-hidden ">
                            {extenstionType.map((extentionType, index)=>(
                                <Listbox.Option key={extentionType.id} value={extentionType}
                                    disabled={(auctionData.template_name === "Single Lot" || auctionData.template_name === "Single lot") ? 
                                        (extentionType.name !== 'Individual Lots' && extentionType.name !== 'Individual lots') : 
                                        ((profile.free_user===true || profile?.plan_type?.includes('Free') || 
                                    profile?.plan_type?.includes('Starter')) &&
                                    extentionType.name !== 'All Lots')}
                                    as="div" 
                                    className="pl-[18px] pr-[15px] pt-2 list-none
                                text-[12px]  leading-normal font-medium text-[#343434]">
                                    {({ active, selected,disabled }) => (
                                        <li className={`py-2 -mt-0.5 rounded-[11px] px-2.5 ${
                                            !disabled && active ? 'bg-[#E6E6E6] cursor-pointer' : 
                                                `bg-white ${disabled?'text-[#A1A1A9] cursor-auto':
                                                    'text-[#343434] cursor-pointer'}`
                                        }`}>
                                            {extentionType.name}
                                        </li>
                                    )}
                                </Listbox.Option>
                            ))}
                        </div>
                    </Transition>
                </Listbox.Options>
            </Listbox>
            {/* <!-- time between lots --> */}
            {(auctionData.extension_type==='Cascade'|| auctionData.extension_type==='Individual Lots') && auctionData.template_name!=="Single Lot" &&
                <div className="pt-3">
                    <div className="flex">
                        <p className="font-medium text-[#343434] text-[12px] leading-normal">Time between lots (mins) </p>
                        <ToolTip 
                            title="Choose how long you would like to extend the auction by if someone bids 
                        in the last minute of the auction">
                            <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                        </ToolTip>
                    </div>
                    <input type="text" autoComplete='off' id="extension_time_between_lots" 
                        disabled={returnDisableState("extension_time_between_lots")}
                        className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] 
                        disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" 
                        value={auctionData.extension_time_between_lots}
                        onChange={(e)=>handleBuyerFeeValueChange(e,'extension_time_between_lots')} 
                        placeholder="2" />
                    {errorMessage && <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                text-[#984848]">{errorMessage}</p>}

                </div>
            }
            <div className="pt-3">
                <div className="flex">
                    <p className="font-medium text-[#343434] text-[12px] leading-normal">Extension time (mins)</p>
                    <ToolTip   
                        title="Choose how long you would like to extend the auction by if someone bids 
                        in the last minute of the auction">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>
                <input id='extension-time' autoComplete='off' type="text" value={auctionData.extension_time}
                    disabled={returnDisableState("extension_time")}
                    onChange={(e)=>handleBuyerFeeValueChange(e,'extension_time')} 
                    className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] 
                    disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" placeholder="2" />

                {errorMessages && <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                text-[#984848]">{errorMessages}</p>}
            </div>
            {/* <!-- dropdown for registration type--> */}
            <Listbox as="div" className="relative w-full" 
                disabled={returnDisableState("registration_type")}
                value={auctionData.registration_type} 
                onChange={(value)=>{setRegistrationTypeShow(true)
                    setAuctionData(prevData=>({...prevData, registration_type: value.name}))
                    setEditedAuctionData(prevData=>({...prevData, registration_type: value.name}))
                }}>
                {({ open }) => (
                    <>
                        <div className="flex mt-4">
                            <p className="text-[12px] font-medium leading-normal text-[#343434]">Registration type</p>
                            <ToolTip   
                                title="Choose your bidder registration requirements. Bidders can register with just their email, 
                        you can require a bank card or manually approve each one.">
                                <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                            </ToolTip>
                        </div>
                        <Listbox.Button type="button" 
                            className={`text-[14px] text-start relative w-full h-11 px-3 border 
                            disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                            border-[#A1A1A9] ${registrationTypeShow?'text-[#343434]':'text-[#A1A1A9]'} 
                            outline-none font-medium leading-normal`} id="registration-type">
                            {auctionData.registration_type===''?'Select':auctionData.registration_type}</Listbox.Button>
                        <img src="/images/dropdown-icon.png" alt="dropdown-icon" className="absolute top-[40px] right-[15px]" 
                            loading="lazy" />
                        <Listbox.Options
                            className="bg-white rounded-[8px] z-30 absolute w-full [&[data-te-dropdown-show]]:block 
                shadow-bs1 list-none" aria-labelledby="extension-type">
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="pr-[15px] py-1 h-[120px] customScrollbar overflow-y-auto overflow-x-hidden">
                                    {registrationType.map((registrationType) => (
                                        <Listbox.Option key={registrationType.id} value={registrationType} 
                                            disabled={(profile.free_user===true || profile.plan_type==='Free' || 
                                            profile.plan_type==='Starter' || (isCardValid && registrationType.name =='Credit (bank) card validation') || (isManual && registrationType.name =='Manual approval')) &&
                                            (registrationType.name !== 'Email only')}
                                            as="div" className="pl-[18px] pr-[15px] pt-2 
                        text-[12px]  leading-normal font-medium text-[#A1A1A9] list-none">
                                            {({ active, selected,disabled }) => (
                                                <li className={`py-2 -mt-0.5 rounded-[11px] px-2.5 
                                                ${!disabled && active ? 'bg-[#E6E6E6] cursor-pointer' : 
                                                    `${disabled?'text-[#A1A1A9] cursor-auto bg-white':
                                                        'text-[#343434] cursor-pointer bg-white'}`
                                                }`}>
                                                    {registrationType.name}
                                                </li>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </div>
                            </Transition>
                        </Listbox.Options>
                    </>
                )}
            </Listbox>
            {/* <!-- dropdown for add buyer type--> */}
            <Listbox as="div" 
                disabled={returnDisableState("add_buyer_fees")}
                className="relative w-full" value={auctionData.add_buyer_fees} 
                onChange={(value) => {setBuyerFeesTypeShow(true)
                    setAuctionData(prevData=>({...prevData,add_buyer_fees: value.name}))
                    setEditedAuctionData(prevData=>({...prevData,add_buyer_fees: value.name}))
                }}>
                <div className="flex mt-4">
                    <p className="text-[12px] font-medium leading-normal text-[#343434]">Add buyer fees</p>
                    <ToolTip   
                        title="You can add additional fees to the buyer either as a percentage of the 
                        winning bid price or as a flat fee.">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>
                <Listbox.Button type="button" 
                    className={`text-[14px] text-start relative w-full h-11 px-3 border 
                    disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                    border-[#A1A1A9] ${buyerFeesTypeShow?'text-[#343434]':'text-[#A1A1A9]'} 
                    outline-none font-medium leading-normal`} id="add_buyer_fee" data-testid="add_buyer_fee"
                    aria-expanded="false"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    {auctionData.add_buyer_fees===''?'Select':auctionData.add_buyer_fees}</Listbox.Button>
                <img src="/images/dropdown-icon.png" alt="dropdown-icon" className="absolute top-[40px] right-[15px]" 
                    loading="lazy" />
                <Listbox.Options 
                    className="bg-white rounded-[8px] z-30  absolute w-full [&[data-te-dropdown-show]]:block 
                shadow-bs1 list-none" aria-labelledby="add_buyer_fee"
                    data-te-dropdown-menu-ref>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className=" pr-[15px] py-1 h-[125px] customScrollbar overflow-y-auto overflow-x-hidden">
                            {buyerFees.map((fees, index)=>(
                                <Listbox.Option key={fees.id} value={fees}
                                    disabled={(profile.free_user===true || profile.plan_type==='Free') &&
                                (fees.name !== 'No additional fees (default)')}
                                    as="div"  
                                    className="pl-[18px] pr-[15px] pt-2 list-none
                        text-[12px]  leading-normal font-medium text-[#4A4A4A]">
                                    {({ active, selected, disabled }) => (
                                        <li className={`py-2 -mt-0.5 rounded-[11px] px-2.5 
                                        ${!disabled && active ? 'bg-[#E6E6E6] cursor-pointer' : 
                                            `${disabled?'text-[#A1A1A9] cursor-auto bg-white':
                                                'text-[#343434] cursor-pointer bg-white'}`
                                        }`}>
                                            {fees.name}
                                        </li>
                                    )}
                                </Listbox.Option>
                            ))}
                        </div>
                    </Transition>
                </Listbox.Options>
            </Listbox>
            {/* <!-- percentage --> */}
            {auctionData.add_buyer_fees==='Add percentage'&&
                <div className="pt-3">
                    <div className="flex">
                        <p className="font-medium text-[#343434] text-[12px] leading-normal">Percentage</p>
                        <ToolTip   
                            title="You can add additional fees to the buyer either as a percentage of the 
                            winning bid price or as a flat fee.">
                            <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                        </ToolTip>
                    </div>
                    <input autoComplete='off' id="percentage-value" type="text" 
                        disabled={auctionData.status=="Published" || auctionData.status=="Accepting bids"}  
                        className="border border-[#A1A1A9] w-full px-3 py-3 outline-none 
                        disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" 
                        value={auctionData.percentage===NaN?'':auctionData.percentage}
                        onChange={(e)=>handleBuyerFeeValueChange(e,'percentage')} 
                        placeholder="%" />
                </div>
            }
            {/* <!-- fees --> */}
            {auctionData.add_buyer_fees==='Add fixed fee'&&
            <div className="pt-3">
                <div className="flex">
                    <p className="font-medium text-[#343434] text-[12px] leading-normal">Fees</p>
                    <ToolTip   
                        title="You can add additional fees to the buyer either as a percentage of the 
                        winning bid price or as a flat fee.">
                        <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                    </ToolTip>
                </div>
                <input  autoComplete='off' id="fees" type="text" 
                    disabled={auctionData.status=="Published" || auctionData.status=="Accepting bids"}  
                    className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] 
                    disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" 
                    value={auctionData.fees} onChange={(e)=>handleBuyerFeeValueChange(e,'fees')}
                    placeholder={currencySymbolMapping[auctionData.currency] || 'N/A'} />
            </div>}
        </div>
    )
}
