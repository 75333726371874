/* eslint-disable security/detect-object-injection */
/* eslint-disable indent */
import { Menu } from "@headlessui/react";
import { useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { currencySymbolMapping } from "../../CommonComponent/staticValues";
import { PaymentInfoModal } from "../Auction/auctionView/sales/paymentInfoModal"
import { PaymentStatus } from '../../constant'

export default function SalesRow(props){

    const { sales, onChangePaymentInfo } =props;
    const [isPaymentInfoModalOpen, setIsPaymentInfoModalOpen] = useState(false)
    const auctionData = useSelector((state)=>state.auction);
    const Navigate=useNavigate()

    const convertTimestampToDayMonthYear = (timestamp) => {
        if(timestamp===0){
            return '-';
        }
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const date = new Date(timestamp*1000) ; // Multiply by 1000 for milliseconds
        const day = date.getDate().toString().padStart(2, '0');
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        // Formatting the date as "1 Jan 2023"
        let formattedDate = `${day} ${months[monthIndex]} ${year}`;
        if(formattedDate.includes('1970')){
            formattedDate = "-"
        }
        return formattedDate;
    };

    const statusClasses =(status)=>{
        switch(status){
        case 'Paid':
            return 'bg-[#A8D9C8] text-[#489892]';
        case 'Failed':
            return 'bg-[#E3C79D] text-[#B98B47]';
        case 'Pending':
            return 'bg-[#E6E6E6] text-[#343434]';
        case 'Cancelled':
            return 'bg-[#DDD1D5] text-[#984848]';
        default :
            return 'bg-[#E6E6E6] text-[#343434]';
        }
    }
    

    return( 
        sales?.map((sale,index)=>
            <tr className="border-b border-[#DEDEDE]" key={index} data-element={index} >
                <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium  ">
                    <input type='checkbox' /> </td>
                <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium ">
                    {sale.order_number}</td>
                <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium "
                >
                    {(sale.name==="" || sale.name===" ")  ? '-' : (sale.name.length>20 ? sale.name.slice(0,20)+'...' : sale.name)}
                </td>
                <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium cursor-pointer hover:underline"
                    onClick={()=>Navigate(`/auctions/view/${auctionData._id}/order-details/${sale._id}`)}>
                    {(sale.auction_title==="" || sale.auction_title===" ")  ? '-' : 
                        (sale.auction_title.length>40 ? sale.auction_title.slice(0,40)+'...' : sale.auction_title)}
                </td>
                <td className=" text-[#343434] px-4 py-3 text-[16px] leading-normal font-medium ">
                    {(sale.created_at===null || sale.created_at==='')  ? '-' : 
                        convertTimestampToDayMonthYear(sale.created_at)}</td>
                <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                    {currencySymbolMapping[sale.currency.toUpperCase()]}
                    {(sale.amount===0 || sale.amount===undefined) ? "-" : Number(sale.amount).toLocaleString('en-IN', {
                        
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </td>

                <td className=" text-[#343434] px-5 py-3 text-[14px]  leading-normal font-medium">
                    {sale.payment===""  ? '-' : sale.payment}
                </td>
           
                <td className=" text-[#343434] px-5 py-3  text-[14px] 
                 leading-normal font-medium flex justify-center items-center">
                    <div className={` py-[8px] px-[16px] text-[14px]  w-[100px] text-center
                            rounded-[42px] ${statusClasses(sale.payment_status)}
                    `} >
                        {sale.payment_status}</div>
                </td>
           
                <td className=" sm:relative  sm:bg-transparent bg-[#fff] top-0 right-0">
                    <div className=" h-full sm:shadow-none shadow-bs7 sm:px-8 flex justify-center items-center ">
                        <span className="flex justify-end ">
                            <Menu as="div" className="relative" >
                                <Menu.Button>
                                    <img src="/images/three-dots-icon-black.svg" alt="img" 
                                        className="cursor-pointer three-dots-icon" type="button"
                                        id="action-purchase" data-testid="action-purchase"/>
                                </Menu.Button>
                                <Menu.Items>
                                    <div style={{"top" : "-70px"}} className="dropdown-cont absolute
                                 overflow-hidden rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 min-w-max right-4 z-[50] px-2
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 ">
                                        <Menu.Item>
                                            <button disabled={sale.payment_status=="Pending"} data-testid="view-purchase"
                                                onClick={()=>
                                                    Navigate(`/auctions/view/${auctionData._id}/order-details/${sale._id}`)}
                                                className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto text-left
                                             block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] cursor-pointer
                                                             hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                            >View</button>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <button data-testid='all-sales-change-payment-info' 
                                                onClick={() => setIsPaymentInfoModalOpen(true)}
                                                disabled={sale.payment_status === PaymentStatus.PAID}
                                                className={`block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-1 
                                                    text-left text-[12px] font-medium text-[#343434] cursor-pointer
                                                    hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                    ${sale.payment_status === PaymentStatus.PAID
                                                    ? 'text-gray-400 cursor-not-allowed' : ''}
                                                `}
                                            >Change payment info</button>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <button 
                                            // onClick={()=>{setDeleteLotModal(true); setLotNumber(lot.lot_number);
                                            //     setLotTitle(lot.title1) }}
                                                className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 
                                            hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer text-left"
                                                data-testid="delete-lot"
                                            >Send payment request</button>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Menu>
                        </span>  
                    </div> 
                </td>
                <PaymentInfoModal
                    isOpen={isPaymentInfoModalOpen}
                    setIsOpen={setIsPaymentInfoModalOpen}
                    handleOK={(data) => onChangePaymentInfo(sale._id, data)}
                    loader={false}
                    paymentStatus={sale.payment_status}
                    paymentType={sale.payment}
                />
            </tr>
    
        )
    )
}