import React, { useState } from 'react'
import { Switch } from '@headlessui/react'

export default function SetUpPrivacySetting({auctionData,setAuctionData, setEditedAuctionData}) {

    const returnClassForDisablingHideAuctionLotToggle = () => {
        const startDate = new Date(auctionData.start_date).getTime();
        const currentDate = new Date().getTime();
        return (startDate <= currentDate || auctionData.template_name==='Single Lot') ? ' opacity-50 pointer-events-none' : ''
    }

    return (
        <div className="privacy-content">
            <div className="flex items-center pt-4">
                <Switch
                    checked={auctionData.publish_auction_results}
                    onChange={(value)=>{setAuctionData(prevData => ({
                        ...prevData,
                        publish_auction_results: value,
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        publish_auction_results: value,
                    }))}}
                    className="border w-[90px] h-[26px] flex items-center 
                justify-between border-[#A1A1A9] rounded-[54px] relative text-[10px] 
                font-medium leading-normal px-2"
                >
                    Yes
                    <span
                        className={`${
                            auctionData.publish_auction_results ? '-translate-x-6' : 'translate-x-5'
                        } text-[10px] font-medium leading-normal rounded-xl min-w-[36px] cursor-pointer h-5 flex justify-center 
                    items-center bg-[#343434] text-[#FFFFFF]
                    m-0.5 text-center`}
                    >{auctionData.publish_auction_results?'Yes':'No'}</span>
                    No
                </Switch>
                <p className="font-medium ml-2.5 text-[#343434] text-[10px] leading-normal">Publish auction results</p>
            </div>
            <div className={`flex items-center pt-4${returnClassForDisablingHideAuctionLotToggle()}`}>
                <Switch
                    checked={auctionData?.hide_auction_lots}
                    onChange={(value)=>{setAuctionData(prevData => ({
                        ...prevData,
                        hide_auction_lots: value,
                    })), setEditedAuctionData(prevData => ({
                        ...prevData,
                        hide_auction_lots: value,
                    }))}}
                    className="border w-[90px] h-[26px] flex items-center 
                justify-between border-[#A1A1A9] rounded-[54px] relative text-[10px] 
                font-medium leading-normal px-2"
                >
                    Yes
                    <span
                        className={`${
                            auctionData?.hide_auction_lots ? '-translate-x-6' : 'translate-x-5'
                        } text-[10px] font-medium leading-normal rounded-xl min-w-[36px] cursor-pointer h-5 flex justify-center 
                    items-center bg-[#343434] text-[#FFFFFF]
                    m-0.5 text-center`}
                    >{auctionData?.hide_auction_lots?'Yes':'No'}</span>
                    No
                </Switch>
                <p className="font-medium ml-2.5 text-[#343434] text-[10px] leading-normal">Hide lots until auction starts</p>
            </div>
            <div className="flex items-center pt-4">
                <Switch
                    checked={auctionData.show_bidding_history}
                    onChange={(value)=>{setAuctionData(prevData => ({
                        ...prevData,
                        show_bidding_history: value,
                    })),setEditedAuctionData(prevData => ({
                        ...prevData,
                        show_bidding_history: value,
                    }))}}
                    className="border w-[90px] h-[26px] flex items-center 
                justify-between border-[#A1A1A9] rounded-[54px] relative text-[10px] 
                font-medium leading-normal px-2"
                >
                    Yes
                    <span
                        className={`${
                            auctionData.show_bidding_history ? '-translate-x-6' : 'translate-x-5'
                        } text-[10px] font-medium leading-normal rounded-xl min-w-[36px] cursor-pointer h-5 flex justify-center 
                    items-center bg-[#343434] text-[#FFFFFF]
                    m-0.5 text-center`}
                    >{auctionData.show_bidding_history?'Yes':'No'}</span>
                    No
                </Switch>
                <p className="font-medium ml-2.5 text-[#343434] text-[10px] leading-normal">
                    Show bidding history</p>
            </div>
            <div className={`flex items-center pt-4${!auctionData.show_bidding_history
                ? ' opacity-50 pointer-events-none' : ''}`}>
                <Switch
                    disabled={!auctionData.show_bidding_history}
                    checked={auctionData.show_bidder_location_in_bidder_history}
                    onChange={(value)=>{setAuctionData(prevData => ({
                        ...prevData,
                        show_bidder_location_in_bidder_history: value,
                    })),setEditedAuctionData(prevData => ({
                        ...prevData,
                        show_bidder_location_in_bidder_history: value,
                    }))}}
                    className="border w-[90px] h-[26px] flex items-center 
                justify-between border-[#A1A1A9] rounded-[54px] relative text-[10px] 
                font-medium leading-normal px-2"
                >
                    Yes
                    <span
                        className={`${
                            auctionData.show_bidder_location_in_bidder_history ? '-translate-x-6' : 'translate-x-5'
                        } text-[10px] font-medium leading-normal rounded-xl min-w-[36px] cursor-pointer h-5 flex justify-center 
                    items-center bg-[#343434] text-[#FFFFFF]
                    m-0.5 text-center`}
                    >{auctionData.show_bidder_location_in_bidder_history?'Yes':'No'}</span>
                    No
                </Switch>
                <p className="font-medium ml-2.5 text-[#343434] text-[10px] leading-normal">
                    Show bidder location in lot bid history</p>
            </div>
            <div className="flex items-center pt-4">
                <Switch
                    checked={auctionData.make_your_auction_private}
                    onChange={(value)=>{
                        setAuctionData(prevData => ({
                            ...prevData,
                            make_your_auction_private: value,
                        })),
                        setEditedAuctionData(prevData => ({
                            ...prevData,
                            make_your_auction_private: value,
                        }))
                        if(value===false){
                            setAuctionData(prevData => ({
                                ...prevData,
                                passcode: '',
                            })),
                            setEditedAuctionData(prevData => ({
                                ...prevData,
                                passcode: '',
                            }))                    
                        }
                    }
                    }
                    className="border w-[90px] h-[26px] flex items-center 
                justify-between border-[#A1A1A9] rounded-[54px] relative text-[10px] 
                font-medium leading-normal px-2"
                >
                    Yes
                    <span
                        className={`${
                            auctionData.make_your_auction_private ? '-translate-x-6' : 'translate-x-5'
                        } text-[10px] font-medium leading-normal rounded-xl min-w-[36px] cursor-pointer h-5 flex justify-center 
                    items-center bg-[#343434] text-[#FFFFFF]
                    m-0.5 text-center`}
                    >{auctionData.make_your_auction_private?'Yes':'No'}</span>
                    No
                </Switch>
                <p className="font-medium ml-2.5 text-[#343434] text-[10px] leading-normal">Make your auction private</p>
            </div>
            {/* <!-- passcode --> */}
            {auctionData.make_your_auction_private &&<div className="pt-3">
                <p className="font-medium text-[#343434] text-[12px] leading-normal">Passcode</p>
                <input autoComplete='off' type="text" id='passcode' 
                    maxLength={16}
                    value={auctionData.passcode}
                    onChange={(e)=>{setAuctionData(prevData => ({
                        ...prevData,
                        passcode: e.target.value,
                    })),setEditedAuctionData(prevData => ({
                        ...prevData,
                        passcode: e.target.value,
                    }))}}
                    className="border mt-1 border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] 
                leading-normal font-medium text-[#343434] placeholder:text-[#A1A1A9]" placeholder="Enter code" />
            </div>}
        </div>  
    )
}
