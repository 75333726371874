import React, { useEffect, useState } from 'react'
import AuctionHeader from './auctionHeader'
import ChooseLayout from './chooseLayout'
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { dataService } from '../../../services/dataService';

export default function CreateAuction({edit}) {
    const path=useLocation();
    const Navigate=useNavigate();
    const {id, uid} = useParams()
    const [auctionData, setAuctionData] = useState({})
    const [isBanner, setIsBanner] = useState(true);

    //sessionStorage.setItem('storedRoute', path.pathname);
    /**
     * The function `handleView` retrieves auction data from a data service and updates the local
     * storage and state variables accordingly.
     */
    const handleView = async()=>{
        const data = await dataService.viewAuction(`?auction_id=${id}`)
        if(data.error===false){
            localStorage.setItem('selectedTemplateId',data.data.data.template_name==='Classic'?1:
                (data.data.data.template_name==='Standalone'?3:2))
            setAuctionData(data.data.data)
        }
    }

    const fetchAuctions=async()=>{
        const endpoint="?page=1&per_page=10"
        const plan_type=localStorage.getItem('plan_type')
        const data=await dataService.AuctionList(endpoint);
        if(data.data.total_auctions>1 && plan_type==="Free"){
            Navigate('/page-not-found');
        }
    }

    useEffect(()=>{
        if(edit!==undefined){
            handleView()
        }else{
            fetchAuctions();
        }
    },[])
    return (
        <div className='bg-[#F4F4F5]'>
            <AuctionHeader isBanner={isBanner} setIsBanner={setIsBanner} />
            <ChooseLayout auctionData={edit!==undefined ? auctionData:undefined}/>
        </div>
    )
}
