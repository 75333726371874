/* eslint-disable max-len */
import { Menu } from "@headlessui/react";
import { motion } from 'framer-motion'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AcceptBidders from "./acceptBidders";

export default function Bidders(props){

    const { bidders,handleBidders,auctionData,loader,acceptBidderModal,setAcceptBidderModal }=props;
    const [lotNumber,setLotNumber]=useState('')
    const {id,uid}=useParams();
    const Navigate = useNavigate();
    
    const handleDate=(date)=>{
        let formatted=date?.split(' ');
        const inputDate = new Date(formatted[0]);
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
        const day = inputDate.getDate();
        const month = months[inputDate.getMonth()];
        const year = inputDate.getFullYear();
        if(year===1970){
            return '-';
        }
    
        return `${day < 10 ? `0${day}` : day} ${month} ${year}`;
    }

    return(
        <>

            {acceptBidderModal && <AcceptBidders setAcceptBidderModal={setAcceptBidderModal} 
                lotNumber={lotNumber} loader={loader}
                acceptBidderModal={acceptBidderModal} handleBidders={handleBidders} />}
            {bidders.map((bidder,index)=>(
                <motion.tr className="border-b  border-[#DEDEDE] table-row h-[60px] " 
                    key={index}
                    id= "list-items"
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.002 }}>
                    <td data-testid="paddle_number"  className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium text-center">
                        {bidder.paddle===undefined?'-':bidder.paddle}</td>
                    <td onClick={()=>Navigate(`/auctions/${id}/${uid}/bidders/${bidder._id}`)}
                        className=" text-[#343434] px-4 py-3 h-[67px] text-[14px] text-center leading-normal font-medium hover:underline cursor-pointer">
                        {bidder.name=="" || bidder.name==" " ? '-' : bidder.name}</td>
                    <td data-testid="bidder_email" className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                        {bidder.email_address}
                    </td>
                    <td className=" text-[#343434] px-4 py-3 h-[67px] text-[14px] text-center leading-normal font-medium">
                        {handleDate(bidder.created_at)}
                    </td>
                    <td className=" text-[#343434] px-4 py-3 h-[67px] text-[14px] text-center leading-normal font-medium 
                    flex items-center justify-center">
                        <img src={bidder.marketing!==false?'/images/publishSuccess.svg':'/images/closeicon-errormsg.svg'} 
                            className="w-5"/></td>
                    <td>
                        <div data-testid="bidder_status" 
                            className={`px-4 py-2.5 text-[14px] text-center leading-normal font-medium w-[120px] rounded-[42px]
                        m-auto 
                        ${bidder.status==='Approved'?'bg-[#A8D9C8] text-[#489892]': 
                    (bidder.status==='Pending'?'bg-[#E3C79D] text-[#B98B47]':'bg-[#CA8787] text-[#984848]')}`}>
                            {bidder.status}</div></td>
                    <td className=" sm:relative  sm:bg-transparent bg-[#fff] sticky top-0 right-0 min-w-[90px] 
                        flex justify-center py-[17px]">
                        <div className="h-fit sm:shadow-none shadow-bs7 ">
                            <span className="flex justify-end ">
                                <Menu as="div" className="relative" >
                                    <Menu.Button data-testid={bidder.status === 'Pending' ? `pending_action_bar` : 'action_bar'} data-elementid={index}>
                                        <img src="/images/three-dots-icon-black.svg" alt="img" 
                                            className="cursor-pointer three-dots-icon" type="button"
                                            id="action-lot"
                                            data-testid="action" />
                                    </Menu.Button>
                                    <Menu.Items>
                                        <div className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 px-2 min-w-max right-0 z-[50] 
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 -top-[94px]">
                                            <Menu.Item>
                                                <Link data-testid='view-bidder'
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                                    to={`/auctions/${id}/${uid}/bidders/${bidder._id}`} >View</Link>
                                            </Menu.Item>
                                            {bidder.status==='Pending' &&
                                            <>
                                                <Menu.Item onClick={()=>
                                                    setAcceptBidderModal({isModalOpen: true, type: 'approve', 
                                                        id: bidder._id, auction_id: auctionData._id})}>
                                                    <a data-testid='accept_buyer' 
                                                        className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] cursor-pointer 
                                                            hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                                    >Approve</a>
                                                </Menu.Item>
                                                <Menu.Item onClick={()=>
                                                    setAcceptBidderModal({isModalOpen: true, type: 'reject', 
                                                        id: bidder._id, auction_id: auctionData._id})}>
                                                    <a 
                                                        className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer
                                                            hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                                        data-testid="delete-lot"
                                                    >Reject</a>
                                                </Menu.Item>
                                            </>
                                            }
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </span>  
                        </div> 
                    </td>
                </motion.tr>
            ))}
        </>
        
    );
}
