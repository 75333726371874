import React, { useCallback, useEffect, useState } from 'react'
import Filter from './filter';
import ExportLotModel from './exportModal';
import Debounce from '../../../../CommonComponent/debounce';

export default function SalesTabHeader({salesInfo,auctionData,handleSearch, searchParam, searchValue,setSearchValue, 
    setShowExportToastMsg,sales, loading, getFilter, isFilterApplied, setSearchParam, setIsFilterApplied}) {

    let plan_type=localStorage.getItem('plan_type');

    const [selected, setSelected] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [date, setDate] = useState(searchParam.get('start_date')!==null?searchParam.get('start_date'):'');
    const [toDate, setToDate] = useState(searchParam.get('end_date')!==null?searchParam.get('end_date'):'');
    const [sendToDate, setsendToDate] = useState('');
    const [sendFromDate, setsendFromDate] = useState('');
    const [showExportModel, setShowExportModel]=useState(false)
    const [exportBtnLoading, setExportBtnloading] =useState(false)

    useEffect(()=>{
        setSearchValue(searchParam.get('keyword') ? decodeURIComponent(searchParam.get('keyword')) : '')
    },[searchParam])

    const titleRegex = /^(?![\s]).*$/;
    /**
     * The handleChange function checks if a value matches a regular expression, and if it does, it
     * sets the search value and calls another function with the search value as a parameter.
     * @returns If the value does not pass the `titleRegex` test, nothing is being returned.
     */
    const handleChange = (e,value)=>{
        handleSearch(e,'keyword',value)
    }

    const changeHandler=(e)=>{
        let value=e.target.value;
        if(titleRegex.test(value)) {
            setSearchValue(value)
        }else{
            return;
        }
    }

    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParam]
    );
    /**
     * The `resetHandler` function resets various state variables and removes specific properties from
     * an object.
     */
    const resetHandler=(e)=>{
        setIsFilterApplied(false);
        let params = Object.fromEntries(searchParam);
        delete params.start_date
        delete params.end_date
        delete params.status
        delete params.type
        setSelectedType('')
        setSelected('')
        setDate('')
        setsendFromDate('')
        setsendToDate('')
        setToDate('')
        setSearchParam({...params});
    }
    const exportModelHandler = () =>{
        setShowExportModel(true)
    }
    const exportHandler=()=>{
        setExportBtnloading(true)
        setTimeout(()=>{
            setExportBtnloading(false)
            setShowExportModel(false)
            setShowExportToastMsg(true)
        }, 1500) 
        setTimeout(()=>{
            setShowExportToastMsg(false)
        },3000)
        let params = Object.fromEntries(searchParam);
        params['export'] = 1
        setSearchParam({...params})
    }
    return (
        <div className="mt-6 flex justify-between flex-wrap items-center">
            <p data-testid='lot-count' className="text-[16px] leading-normal font-medium text-[#343434]"> 
                <span>{salesInfo.total_records} </span> 
                {salesInfo.total_records===1 ? 'Sale' : 'Sales'}
            </p>
            <div className='flex flex-col'>
                <div className="flex ml-auto  flex-wrap items-center">
                    {/* <!-- search  --> */}
                    <div className="relative pr-3 py-[12px]">
                        <input type="text" id="search-lot" data-testid="search" debounceTimeout={500}
                            className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3 border 
                                border-[#A1A1A9] placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none 
                                font-medium leading-normal text-[#4A4A4A]" placeholder="Search"
                            value={searchValue} 
                            onChange={(e)=>{e.preventDefault(); optimizedFn(e,e.target.value); changeHandler(e)}}
                        />
                        <img src="/images/search-icon-large.svg" alt="search-icon" 
                            className="absolute top-5 cursor-pointer 
                                left-2.5" />
                    </div>
                    {/* <!-- filter --> */}
                    <Filter searchParam={searchParam} 
                        getFilter={getFilter}
                        selected={selected}
                        setSelected={setSelected}
                        date={date}
                        setDate={setDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        sendToDate={sendToDate}
                        setsendToDate={setsendToDate}
                        sendFromDate={sendFromDate}
                        setsendFromDate={setsendFromDate}
                        selectedType={selectedType} 
                        setSelectedType={setSelectedType}
                    />
                    {/* <!-- export button -->/ */}
                    {sales.length>0 &&
                    <div className="relative mr-2.5 cursor-pointer">
                        <button 
                            id="export-orders"
                            onClick={exportModelHandler} 
                            type="button" 
                            className="text-[14px] sm:flex hidden text-[#52525A] border w-[89px] items-center 
                            disabled:text-[hsl(240,4%,65%)] disabled:border-[#D4D4D8] hover:bg-[#EFF1F8]
                            h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium justify-center">
                            <img src="/images/export-icon.svg" alt="export-icon" 
                                className={`mr-2  `} 
                            />
                            Export
                        </button>
                    </div>}
                </div>
                {/* <!-- filter applied content --> */}
                {isFilterApplied && <div className="filter-applied ">
                    <div className="flex justify-end items-center">
                        <p className="text-[14px] text-[#343434] leading-normal pr-2 font-medium">Filter applied</p>
                        <button className="underline text-[14px] text-[#343434] leading-normal font-medium 
                    border-l border-[#343434] pl-2" onClick={(e)=>resetHandler(e)} >Reset</button>
                    </div>
                </div>}
            </div>
            {showExportModel && <ExportLotModel isOpen={showExportModel} setIsOpen={setShowExportModel}
                loader={exportBtnLoading} handleExport={exportHandler}/>}
        </div>    
    )
}
