/* eslint-disable max-len */
import { Link, useNavigate, useParams } from "react-router-dom"
import ImportLotModal from "./importLotModal";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useCallback, useState } from "react";
import Debounce from "../../../../CommonComponent/debounce";
import ExportAllModel from "../exportModal";

export default function LotTabHeader({lotInfo,auctionData,handleSearch, searchParams, searchValue,setSearchValue, 
    handleImportFile, uploadedFileName, setUploadedFileName, showImportModal, setShowImportModal, setShowExportToastMsg,
    importLoader, setImportLoader, lots, loadLots, isReorderLotModalOpen, setIsReorderLotModalOpen, setSearchParams}){

    const { id }=useParams();
    const [showExportModel, setShowExportModel]=useState(false)
    const [exportBtnLoading, setExportBtnloading] =useState(false)
    const lotsExportCsvUrl = lotInfo.csv_url
    const titleRegex = /^(?![\s]).*$/;

    const handleChange = (e,value)=>{
        handleSearch(e,'search',value)
    }

    const changeHandler=(e)=>{
        let value=e.target.value;
        if(titleRegex.test(value)) {
            setSearchValue(value)
            
        }else{
            return;
        }
    }
    const optimizedFn = useCallback(
        Debounce((e,value) => handleChange(e,value)),
        [searchParams]
    );

    const Navigate=useNavigate();
    let plan_type=localStorage.getItem('plan_type').replace(/^"|"$/g, '');

    const exportModelHandler = (e) =>{
        setShowExportModel(true)
    }

    const exportHandler=()=>{
        setExportBtnloading(true)
        let params = Object.fromEntries(searchParams);
        params['lotExport'] = true
        setSearchParams({...params})
        
        setTimeout(()=>{
            setExportBtnloading(false)
            setShowExportModel(false)
            setShowExportToastMsg(true)
        }, 1500) 
        setTimeout(()=>{
            setShowExportToastMsg(false)
        },3000)
    }
  
    return(
        <div className="mt-6 flex justify-between flex-wrap items-center">
            <p data-testid='lot-count' className="text-[16px] leading-normal font-medium text-[#343434]"> 
                <span>{lotInfo.total_records_found} </span> 
                {lotInfo.total_records_found===1 ? 'Lot' : 'Lots'}
            </p>
            <div className="flex ml-auto  flex-wrap items-center">
                {/* <!-- search  --> */}
                <div className="relative pr-3 py-[12px]">
                    <input type="text" id="search-lot" data-testid="search-lot" debounceTimeout={500}
                        className="text-[14px] relative w-[270px] h-[32px] pl-8 pr-3 border 
                                border-[#A1A1A9] placeholder:text-[#A1A1A9] focus:border-[#343434] outline-none 
                                font-medium leading-normal text-[#4A4A4A]" placeholder="Search lots"
                        value={searchValue} 
                        onChange={(e)=>{e.preventDefault(); optimizedFn(e,e.target.value), changeHandler(e)}}
                    />
                    <img src="/images/search-icon-large.svg" alt="search-icon" 
                        className="absolute top-5 cursor-pointer 
                                left-2.5" />
                </div>
                {/* <!-- export button -->/ */}
                {auctionData.status === 'Draft' ? <div className="relative mr-2.5 cursor-pointer">
                   
                    <button onClick={()=>setShowImportModal(true)} 
                        disabled={plan_type.includes('Free') || (auctionData.status==='cancelled') ||
                        (auctionData.template_name==='Single Lot' || 
                            auctionData.template_name==='Single lot' && lotInfo.total_records_found>=1)} 
                        type="button" id="import-lot" 
                        className="text-[14px] sm:flex hidden text-[#52525A] border w-[89px] items-center 
                            disabled:text-[hsl(240,4%,65%)] disabled:border-[#D4D4D8]
                            h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium justify-center">
                        <img src="/images/import-icon.svg" alt="export-icon" 
                            className={`mr-2 ${plan_type.includes('Free')  ? 'opacity-50' : ''}  `} />
                        import
                    </button>
                    <button type="button" onClick={()=>setShowImportModal(true)} disabled={plan_type.includes('Free') || (auctionData.status==='cancelled') ||
                        (auctionData.template_name==='Single Lot' || 
                            auctionData.template_name==='Single lot' && lotInfo.total_records_found>=1)} className="text-[14px] sm:hidden block text-[#52525A] border w-[35px] disabled:text-[hsl(240,4%,65%)]
                        h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium disabled:border-[#D4D4D8]">
                        <img src="/images/import-icon.svg" alt="export-icon" 
                            className={`ml-2 ${(
                                plan_type.includes('Free') || (auctionData.status==='cancelled') ||
                                (auctionData.template_name==='Single Lot' || 
                                    auctionData.template_name==='Single lot' && lotInfo.total_records_found>=1) ) ? 'opacity-50' : ''} `} />
                    </button>
                </div> : <div className="relative mr-2.5 cursor-pointer">
                    <button onClick={(e)=>exportModelHandler(e)} 
                        disabled={ lots?.length==0 } 
                        type="button" id="export-bidders" 
                        className="text-[14px] sm:flex hidden text-[#52525A] border w-[89px] items-center 
                            disabled:text-[hsl(240,4%,65%)] disabled:border-[#D4D4D8] hover:bg-[#EFF1F8]
                            h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium justify-center">
                        <img src="/images/export-icon.svg" alt="export-icon" 
                            className={`mr-2 ${( lots?.length==0 ) ? 'opacity-50' : ''} ${lots?.length===0 && "opacity-50"} `} />
                        Export
                    </button>
                    <button type="button" onClick={(e)=>exportModelHandler(e)}  disabled={ lots?.length==0 ||
                        (auctionData.template_name==='Single Lot' || 
                            auctionData.template_name==='Single lot' && lotInfo.total_records_found>=1)}  className="text-[14px] sm:hidden block text-[#52525A] border w-[35px] disabled:text-[hsl(240,4%,65%)]
                        h-[32px] border-[#A1A1A9] rounded-[6px] leading-normal  font-medium disabled:border-[#D4D4D8] hover:bg-[#EFF1F8]">
                        <img src="/images/export-icon.svg" alt="export-icon" 
                            className={`ml-2 ${( 
                                (auctionData.template_name==='Single Lot' || 
                                auctionData.template_name==='Single lot' && lotInfo.total_records_found>=1) ) ? 'opacity-50' : ''} ${lots?.length===0 && "opacity-50"} `} />
                    </button>
                </div>}
                <div>
                    {loadLots? 
                        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e0e0e0" className="pt-4">
                            <Skeleton count={1} className='h-[40px] min-w-[155px] pt-4 ' />
                        </SkeletonTheme> : 
                        <button id='create-lot' 
                            disabled={(lotInfo.total_records_found>=10 && plan_type.includes('Free')) || (auctionData.status==='Cancelled') || 
                            ((auctionData.template_name==='Single Lot' || 
                            auctionData.template_name==='Single lot') && lotInfo.total_records_found>=1)}
                            className="w-[95px] h-[32px] bg-[#282828]  text-[#FFFFFF] leading-normal disabled:cursor-auto
                            font-medium text-[14px] rounded-[6px] disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] 
                            hover:bg-[#626465]" 
                            onClick={()=>Navigate(`/auctions/${id}/${auctionData.auction_id}/lots/create`,{state: auctionData})}>
                            Create lot
                        </button>}
                </div>
            </div>
            {showImportModal &&<ImportLotModal showImportModal={showImportModal} setShowImportModal={setShowImportModal}
                handleImportFile={handleImportFile} uploadedFileName={uploadedFileName} 
                setUploadedFileName={setUploadedFileName} importLoader={importLoader} setImportLoader={setImportLoader}/>}
            {/* {isReorderLotModalOpen && <ReorderLotModal isReorderLotModalOpen={isReorderLotModalOpen} 
                setIsReorderLotModalOpen={setIsReorderLotModalOpen} lotsInfo={lots} />} */}
            {showExportModel && <ExportAllModel isOpen={showExportModel} setIsOpen={setShowExportModel}
                loader={exportBtnLoading} handleExport={exportHandler} type="Lot" />}
        </div>
    );
}