/* eslint-disable max-len */
import { Menu } from '@headlessui/react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { timeZoneMap } from '../../CommonComponent/staticValues';
import moment from 'moment/moment';

export default function TableRow(props){

    const { bidders }= props;
    const [selectedRow, setSelectedRow]=useState(null);
    const Navigate = useNavigate()

    /**
     * The function `convertTimestampToDayMonthYear` takes a timestamp as input and returns a formatted
     * date string in the format "day month year".
     * @returns The function `convertTimestampToDayMonthYear` returns a formatted date string in the
     * format "DD MMM YYYY" (e.g., "01 Jan 2023").

     */


    const formatDate = (time_zone,timestamp) => {
        const date = new Date(timestamp); // Convert timestamp to Date
  
        // Format options for date and time
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
            hour12: false, // Force 24-hour format
            timeZone: timeZoneMap[time_zone ? time_zone : 'Asia/Kolkata'],
        };
  
        // Format the date using toLocaleString
        let formatted = date.toLocaleString('en-GB', options);
        if(formatted.includes('GMT+5:30')){
            formatted = formatted.replace('GMT+5:30', 'IST');
        }else if(formatted.includes('GMT+11')){
            formatted = formatted.replace('GMT+11', 'AESR');
        }else if(formatted.includes('GMT+13')){
            formatted = formatted.replace('GMT+13', 'NZST');
        }else if(formatted.includes('GMT+1')){
            formatted = formatted.replace('GMT+1', 'CET');
        }else if(formatted.includes('GMT+9')){
            formatted = formatted.replace('GMT+9', 'JST');
        }else if(formatted.includes('GMT+8')){
            formatted = formatted.replace('GMT+8', 'CST');
        }
  
        formatted = formatted.replace(/,/g, ' /');
  
        return formatted;
    };

    const convertTimestampToDayMonthYear = (time_zone,timestamp) => {

        if(timestamp==0){
            return '-';
        }

        const time =moment.tz(timestamp, timeZoneMap[time_zone ? time_zone : 'Etc/GMT']).format("MMM DD YYYY")

        // const options = {
        //     year: "numeric",
        //     month: "short",
        //     day: "2-digit",
        //     hour: "numeric",
        //     minute: "2-digit",
        //     timeZone: timeZoneMap[time_zone ? time_zone : 'Etc/GMT']
        // };
        // const formatter = new Intl.DateTimeFormat("en-GB", options);

        // const start_date = new Date(timestamp);
        // const formattedDate = formatter.format(start_date);
        // const [date1, time] =formattedDate.includes('at') ? formattedDate.split("at ") : formattedDate.split(", ");
        
        return time.includes('1970') ? '-' : time;
    };
    
    return(
        <>
            {bidders.map((bidder,index)=>
                <tr key={index} 
                    className={`border border-[#DEDEDE] border-b-0 table-row  h-8 
                    hover:bg-[#F4F4F5] ${selectedRow===index ? 'bg-[#F4F4F5]' : ''} `}>
                    <td onClick={()=>Navigate(`/allbidders/${bidder._id}`)}
                        className=" text-[#343434] pl-12 pr-4 py-2 text-[14px] leading-normal font-medium cursor-pointer hover:underline">
                        {bidder.name === '' || bidder.name === undefined || bidder.name === ' '? '-': bidder.name}</td>
                    <td className=" text-[#343434] px-4 py-2 text-[14px] leading-normal font-medium">
                        {bidder.email_address}</td>
                    <td className=" text-[#343434] px-4 py-2 text-[14px] leading-normal font-medium">
                        {/* {bidder.created_at ==='' || bidder.created_at ===undefined ? '-' : convertTimestampToDayMonthYear(bidder.created_at)} */}
                        {convertTimestampToDayMonthYear(bidder.time_zone,bidder.created_at)}

                    </td>
                    <td className=" text-[#343434] pl-4 pr-9 pb-2 pt-4 h-[67px] text-[14px] text-center leading-normal font-medium 
                    flex items-center justify-center" >
                        <img src={`/images/${bidder.marketing === true ? 'truemark.svg' :'falsemark.svg'} `} alt=""  />
                    </td>
                    <td className=" sm:relative  sm:bg-transparent bg-[#fff] sticky top-0 right-0 h-[80px]
                      justify-center">
                        <div className="h-full sm:shadow-none shadow-bs7 sm:px-8">
                            <span className="flex md:justify-end justify-center translate-y-[100%]">
                                <Menu as="div" className="relative" >
                                    <Menu.Button onClick={()=>setSelectedRow(index)} >
                                        <img src="/images/three-dots-icon-black.svg" alt="img" 
                                            className="cursor-pointer three-dots-icon action-bar" type="button"
                                            id="action_bar" data-testid="action-bar" />
                                    </Menu.Button>
                                    <Menu.Items>
                                        <div className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 min-w-max right-0 z-[50] px-2
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3">
                                            <Menu.Item data-testid='view-bidder'>
                                                <button type='button' onClick={()=>Navigate(`/allbidders/${bidder._id}`)}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]">
                                                    View</button>
                                            </Menu.Item>
                                            <Menu.Item data-testid='delete-buyer'>
                                                <button type='button'
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]">
                                                    Delete</button>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </span>  
                        </div> 
                    </td>
                </tr>
            )}
        </>
    );
}