import { createSlice } from '@reduxjs/toolkit';

const initialState={
}

/* The code is creating a Redux slice using the `createSlice` function from the `@reduxjs/toolkit`
library. */
const auctionSlice= createSlice({
    name: 'auction',
    initialState,
    reducers: {
        setAuctionData: (state, action) => {
            return action.payload;
        },
        clearAuctionData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { setAuctionData } = auctionSlice.actions;

export default auctionSlice.reducer;