import { createSlice } from '@reduxjs/toolkit';

const initialState={
}

/* The code is creating a Redux slice using the `createSlice` function from the `@reduxjs/toolkit`
library. */
const socketSlice= createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.instance = action.payload; // Store under 'instance' key to prevent direct state replacement
        },
        clearSocketData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { setSocket } = socketSlice.actions;

export default socketSlice.reducer;