import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import SetupSection from './setUpSection';

export default function SetUpForMobile({isOpen, setIsOpen,lotData,auctionData,setLotData,priceError,
    setPriceError,ismdBanner}){
    return(
        <Drawer
            id="mobile-panel-configuration"
            open={isOpen}
            onClose={()=>setIsOpen((prevState) => !prevState)}
            direction='right'
            className='bg-[#FFFFFF] mobile-setup overflow-auto pb-[65px]'
        >
            <SetupSection lotData={lotData}
                auctionData={auctionData}
                setLotData={setLotData}
                priceError={priceError}
                setPriceError={setPriceError}
                ismdBanner={ismdBanner} />
        </Drawer>
    );
}