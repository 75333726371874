/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import { Storage } from "aws-amplify";
import uuid from 'react-uuid';
import config from "../../../../config";
import {currencySymbolMapping} from '../../../../CommonComponent/staticValues'
import { Oval } from "react-loader-spinner";
import ToolTip from "../../../../CommonComponent/tooltip";
import { QuillModules, RichEditorFormats } from "../../../../config/quill";

export default function SetupSection(props){

    const {setLotData, lotData, priceError,setPriceError, auctionData, text, setText, edit,ismdBanner}=props;
    const [imageEroor, setImageError]=useState("");
    const [imageLoader,setImageLoader] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const placeholder=(auctionData.currency==='' || auctionData.currency===undefined) ? '$0' : 
        currencySymbolMapping[auctionData.currency]+'0';

    
    /**
     * The function `handleChange` logs the content and updates the `description`
     * property of `lotData` based on the content.
     */
    const handleChange= (html)=> {
        setText(html);
        if(html!=='<p><br></p>'){
            setLotData(prevData => ({
                ...prevData,
                description: html
            }))
        }else{
            setLotData(prevData => ({
                ...prevData,
                description: ''
            }))
        }
    }

    /**
     * The function `handleImageClick` updates the selected image index and sets the `featured`
     * property of each image in `lotData.images` based on the selected index.
     */
    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        const updatedImages = lotData.images.map((image, i) => ({
            ...image,
            featured: i === index, // Set 'feature' to true for the selected index, false for others
        }));
        setLotData(prevData => ({
            ...prevData,
            images: updatedImages,
        }))
    };

    /**
     * The `handleImageUpload` function allows the user to upload multiple images, checks for image
     * format and size restrictions, uploads the images to a storage location, and updates the state
     * with the uploaded image URLs.
     * @returns The function `handleImageUpload` does not have a return statement. Therefore, it does
     * not explicitly return anything.
     */
    const handleImageUpload = async (event, imageType) => {
        const selectedImages = Array.from(event.target.files);
        const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
        if(selectedImages.length>5 || lotData.images.length+selectedImages.length>5){
            setImageError("Please select upto 5 images")
            return;
        }
        const uploadedImages = [];
        setImageLoader(true)
        const uploadPromises = selectedImages.map(async (selectedImage) => {
            if(!allowedFileTypes.includes(selectedImage.type.toLowerCase())){
                setImageError("Image Format JPEG, PNG are only allowed")
                setImageLoader(false);
            }
            else if (selectedImage.size <= 5 * 1024 * 1024) {
                setImageError("")
                try {
                    const upload = await Storage.put(`DomainName/Auctions/lots/images/${uuid()}/${selectedImage.name.replaceAll(' ','')}`,
                        selectedImage,
                        {
                            contentType: selectedImage.type,
                            acl: 'public-read',
                        }
                    );
                    // const accelerateOption = {
                    //     AccelerateConfiguration: { Status: 'Enabled'},
                    // };

        
                    uploadedImages.push(upload.key);
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            } else {
                setImageError("Please select a lead image smaller than 5MB")
                setImageLoader(false);
            }
        });
        
        try {
            await Promise.all(uploadPromises);
            uploadedImages.forEach((image, index) => {
                setLotData((prevData) => ({
                    ...prevData,
                    images: [
                        ...prevData.images,
                        {
                            url: image,
                            featured: prevData.images.length === 0, // Set featured to true only if no images exist yet
                        },
                    ],
                }));
            });
            setImageLoader(false);
        } catch (error) {
            console.error('Error uploading images:', error);
            setImageLoader(false);
        }
    };

    /**
     * The function `handleImageDelete` filters out an image from an array of images based on its index
     * and updates the state with the filtered array.
     */
    const handleImageDelete = (imageIndex) => {
        const filtered=lotData.images.filter((image,index)=>index!==imageIndex);
        if (lotData.images[imageIndex].featured) {
            if (filtered.length > 0) {
                filtered[0].featured = true;
                setSelectedImageIndex(0);
            }
        }
        setLotData(prevData => ({
            ...prevData,
            images: filtered,
        }));
    };

    /* The above code is a useEffect hook in a React component. It is used to check if the low estimate
    and high estimate values in the lotData object are valid. If both values are not empty and the
    low estimate is greater than the high estimate, it sets the priceError state to true. Otherwise,
    it sets the priceError state to false. The useEffect hook is triggered whenever the low_estimate
    or high_estimate values in the lotData object change. */
    useEffect(()=>{
        if(lotData.low_estimate!=='' && lotData.high_estimate!==''){
            if(parseInt(lotData.low_estimate) > parseInt(lotData.high_estimate)){
                setPriceError(true);
            }else{
                setPriceError(false);
            }
        }else{
            setPriceError(false);
        }
    },[lotData.low_estimate, lotData.high_estimate])

    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
            addTag();
        }
        if (event.key === "Backspace" && inputValue=="") {
            if (lotData.tags.length > 0) {
                const newTags = [...lotData.tags];
                newTags.pop();
                setLotData(prevData => ({
                    ...prevData,
                    tags: newTags // Add the new tag
                }));
            }
        }
    };

    const handleTagChange = (event) => {
        if(lotData.tags.length<3){
            const inputValueWords = event.target.value.split(' ');
            if (inputValueWords.length > 3) {
            // If more than 3 words are entered, truncate to first 3 words
                setInputValue(inputValueWords.slice(0, 3).join(' '));
            } else {
                setInputValue(event.target.value);
            }
        }else{
            return;
        }
        
    };

    const handleClick = () => {
        addTag();
    };

    const addTag = () => {
        if (inputValue.trim() !== '') {
            setLotData(prevData => ({
                ...prevData,
                tags: [...lotData.tags, inputValue.trim()] // Add the new tag
            }));
            setInputValue('');
        }
    };

    return(
        <div className={`h-full ${ismdBanner ? 'mt-0' : 'mt-[2.25rem]'}`} onClick={handleClick} >
            {/* <!--Tabs navigation--> */}
            <ul
                className="mb-5 flex list-none flex-row flex-wrap pl-0"
                role="tablist"
                data-te-nav-ref>
                <li className="w-[50%]" role="presentation">
                    <p id="setup-auction"
                        className="block cursor-default px-9  pt-7 text-[14px] text-[#A1A1A9] font-medium leading-normal 
                                hover:isolate hover:border-transparent ] data-[te-nav-active]:bg-[#ffffff] bg-[#F4F4F5] 
                                focus:isolate focus:border-transparent data-[te-nav-active]:border-[#D4D4D8] 
                                data-[te-nav-active]:text-[#52525A]"
                        data-te-toggle="pill"
                        data-te-target="#tabs-home"
                        data-te-nav-active
                        role="tab"
                        aria-controls="tabs-home"
                        aria-selected="true"
                    >Add lot</p>
                </li>
            </ul>
            {/* <!--Tabs content--> */}
            <div className="mb-6">
                <div
                    className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                    id="tabs-home"
                    role="tabpanel"
                    aria-labelledby="tabs-home-tab"
                    data-te-tab-active >
                    {/* <!-- auction setup contents --> */}
                    <div className="px-9  pb-[24px]">
                        <div className="flex">
                            <p className="font-medium text-[#343434] text-[14px] leading-normal">Image</p>
                            <ToolTip  title="Lead image is required to save lot.">
                                <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                            </ToolTip>
                        </div>

                        <div>
                            {lotData.images?.map((image,index)=> <div key={index}
                                className="flex  items-center bg-[#fff] border border-[#DFDFDF] py-2 w-full 
                                        shadow-bs5 rounded-[8px] mt-2 px-4">
                                <img src={`${config.Storage.CDN}${image.url}`} alt="add-image-icon" 
                                    className="w-[60px] h-[44px]"/>
                                <div className="relative radio radio-feature ml-2 flex items-center">
                                    <input className="cursor-pointer"
                                        checked={selectedImageIndex === index || (image.featured && selectedImageIndex === null)}
                                        type="radio"
                                        value="images"
                                        id={`${index}image`}
                                        onClick={() => handleImageClick(index)}
                                    />
    
                                    <label
                                        className="inline-block font-medium  text-center  text-[#343434] text-[10px] 
                                                leading-normal pl-3 hover:cursor-pointer"
                                        for={`${index}image`} onClick={() => handleImageClick(index)}>
                                        Featured
                                    </label>
                                </div>
                                <img data-testid='delete-lot-img' src="/images/delete-icon.svg" className="cursor-pointer ml-auto" alt="delete icon" 
                                    onClick={()=>handleImageDelete(index)}/>
                            </div>)}
                            
                            <div className={`w-full  relative bg-[#E6E6E6] py-[22px] px-3 mt-3 rounded-md
                                ${lotData.images.length>=5 && 'opacity-60'} `}>
                                <div className="flex justify-center" >
                                    <div>
                                        <div className="flex justify-center items-center " >
                                            <img src="/images/add-image-icon.svg" alt="add-image-icon"/>
                                            <label for="chooseFile" className=" font-medium text-center pl-2 
                                        text-[#343434] text-[10px] leading-normal">Add image (Max 5)</label>
                                        </div>
                                        <p className="font-medium mt-1 text-center pl-2 text-[#717179]  text-[10px] 
                                    leading-normal">Max size: 5mb Format, PNG, JPG </p>
                                
                                        <input type="file" name="chooseFile" id="lot-image" disabled={lotData.images.length===5}
                                            className="opacity-0 z-10 absolute disabled:cursor-auto cursor-pointer top-0  h-[58px] w-full left-0" 
                                            accept="image/jpeg, image/png" multiple onChange={(e)=>handleImageUpload(e)} />
                                    </div>
                                    {imageLoader&& <Oval
                                        height={20}
                                        width={50}
                                        color="#FFFFFF"
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#000000"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />}
                                </div>
                            </div>
                            {imageEroor!=="" &&
                            <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                            text-[#984848]">{imageEroor}</p>}
                            {/* <!-- after adding logo this content is appear --> */}
                            <div className="w-full hidden h-[84px] justify-center items-center relative 
                                bg-[#E6E6E6] px-3 mt-2 rounded-md">
                      
                                <div className="flex justify-end absolute right-[15px] bottom-[15px]">
                                    <img src="/images/edit-icon-img.svg" className="cursor-pointer" alt="edit"/>
                                    <img src="/images/delete-icon-img.svg" className="cursor-pointer ml-2" alt="delete"/>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[14px] leading-normal">Title 1</p>
                                <ToolTip  title="Main title for your lot e.g. Pablo Picasso">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            <input id="lot-title" type="text" value={lotData.title1} maxLength={120} onChange={(e)=>{
                                if(e.target.value===" "){
                                    return;
                                }else{
                                    setLotData(prevData => ({
                                        ...prevData,
                                        title1: e.target.value,
                                    }));
                                }
                                
                            }} className="border mt-1 border-[#A1A1A9] w-full px-3 py-3 outline-none 
                                        text-[14px] leading-normal text-[#343434] font-medium"/>
                        </div>
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[14px] leading-normal">Title 2</p>
                                <ToolTip  title="Secondary title for your lot e.g. Les Demoiselles d’Avignon, 1907">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            <input type="text" id='lot-title2' value={lotData.title2} maxLength={120} onChange={(e)=>{
                                if(e.target.value===" "){
                                    return;
                                }
                                setLotData(prevData => ({
                                    ...prevData,
                                    title2: e.target.value,
                                }));
                            }} className="border mt-1 border-[#A1A1A9] w-full px-3 py-3 outline-none 
                                        text-[14px] leading-normal text-[#343434] font-medium placeholder:text-[#A1A1A9] 
                                        placeholder:text-[14px]" placeholder="Optional"/>
                        </div> 
                        {/* <!-- description part --> */}
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[14px] leading-normal">Description</p>
                                <ToolTip  title="Cataloguing details of the lot to provide additional details for bidders.">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            <div className="pt-2 lot-description">
                                <ReactQuill
                                    value={text}
                                    theme="snow"
                                    onChange={handleChange}
                                    modules={QuillModules}
                                    placeholder="Lot description"
                                    style={{}}
                                    formats={RichEditorFormats}
                                />
                            </div>
                        </div>
                        {/* <!--starting price  --> */}
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[12px] leading-normal">Starting price</p>
                                <ToolTip   title="Starting price or the 'reserve' is the amount the bidding starts with. ">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            <input id="starting-price" type="text" 
                                disabled={edit && lotData.current_bid!==0}
                                value={lotData.starting_price} 
                                onChange={(e)=>{
                                    if (!/^(?!0)[0-9]*$/.test(e.target.value)) {
                                        return;
                                    } else {
                                        setLotData(prevData => ({
                                            ...prevData,
                                            starting_price: e.target.value,
                                        }));
                                    }
                                
                                }} className="border mt-1 border-[#A1A1A9] w-full disabled:border-[#c9c9cc] disabled:text-[#A1A1A9] disabled:bg-[#F4F4F5]
                                    px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] 
                                    placeholder:text-[#A1A1A9]" placeholder={placeholder}/>
                        </div>
                        {/* <!-- low estimate --> */}
                        <div className="pt-4">
                            <p className="font-medium text-[#343434] text-[12px] leading-normal">Low Estimate</p>
                            <input id="percentage" data-testid="low-estimate" type="text" value={lotData.low_estimate} onChange={(e)=>{
                                if(!/^[0-9]*$/.test(e.target.value)){
                                    return;
                                }
                                setLotData(prevData => ({
                                    ...prevData,
                                    low_estimate: e.target.value,
                                }));
                            }} className="border mt-1 border-[#A1A1A9] w-full 
                                        px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] 
                                        placeholder:text-[#A1A1A9]" placeholder="Optional"/>
                        </div>
                        {/* <!-- high estimate --> */}
                        <div className="pt-4">
                            <p className="font-medium text-[#343434] text-[12px] leading-normal">High Estimate</p>
                            <input id="percentage" data-testid='high-estimate' type="text" value={lotData.high_estimate} onChange={(e)=>{
                                if(!/^[0-9]*$/.test(e.target.value)){
                                    return;
                                }
                                setLotData(prevData => ({
                                    ...prevData,
                                    high_estimate: e.target.value,
                                }));
                            }} className="border mt-1 border-[#A1A1A9] w-full 
                                    px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] 
                                    placeholder:text-[#A1A1A9]" placeholder="Optional"/>
                        </div>
                        {priceError &&
                        <p className="text-[10px] pt-1.5 font-medium leading-normal enddate-errormsg 
                            text-[#984848]">Low estimate value cannot be greater than high estimate value</p>}
                        {/* <!-- shipping details --> */}
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[12px] leading-normal">Shipping details</p>
                                <ToolTip   title="For physical lots, you can add any shipping related information for the buyer.">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            <textarea name="text-area" value={lotData.shipping_details} onChange={(e)=>{
                                if(e.target.value===' '){
                                    return;
                                }
                                setLotData(prevData => ({
                                    ...prevData,
                                    shipping_details: e.target.value,
                                }));
                            }} className="mt-1 border border-[#A1A1A9] w-full px-3 h-[110px] overflow-y-auto overflow-x-hidden 
                                customScrollbar py-2 outline-none text-[14px] text-start resize-none leading-normal font-medium 
                                text-[#343434] placeholder:text-[#A1A1A9]" placeholder="Optional" cols="10" rows="10"></textarea>
                        </div>
                        {/* <!-- tags --> */}
                        <div className="pt-4">
                            <div className="flex">
                                <p className="font-medium text-[#343434] text-[12px] leading-normal">Tags</p>
                                <ToolTip  title="Tags are keywords bidders can search for in the auction page. To add tags, press 'enter' after typing into the field.">
                                    <img src="/images/info-icon.svg" className="pl-2 cursor-pointer" alt="info-icon" loading="lazy" />
                                </ToolTip>
                            </div>
                            {/* <div className="relative">
                                <TagInput data-testid='tags'
                                    trigger={['Enter', 'Tab', ',']}
                                    value={lotData.tags}
                                    placeholder="Max 3 tags"
                                    className="outline-none text-[14px] leading-normal font-medium text-[#343434] 
                                    placeholder:text-[#A1A1A9] border mt-1 border-[#A1A1A9] focus:border-[#A1A1A9]"
                                    style={{ width: '100%' }}
                                    tagRemoveStyle={{ display: 'none' }} 
                                    menuStyle={{ width: '100%' }}
                                    cleanable={false}
                                    onChange={(value, e) => {
                                        if (lotData.tags.length < 3) {
                                            setLotData(prevData => ({
                                                ...prevData,
                                                tags: value // Add the new tag
                                            }));
                                        }else if(lotData.tags.length===3){
                                            if(e.code!=='Enter'){
                                                setLotData(prevData => ({
                                                    ...prevData,
                                                    tags: value // Add the new tag
                                                }));
                                            }
                                        }
                                        else{
                                            return
                                        }
                                    }}
                                />
                            </div> */}
                            <div id="tags_field" className="flex flex-wrap  items-center outline-none text-[14px] leading-normal font-medium text-[#343434] 
                                    placeholder:text-[#A1A1A9] border mt-1 border-[#A1A1A9] focus:border-[#A1A1A9] p-2" >
                                <div className="flex flex-wrap gap-2" >
                                    {lotData.tags.map((tag, index) => (
                                        <div key={index} className="flex bg-[#D9D9D9] p-2 px-4 w-fit rounded-[51px]">
                                            {tag}
                                            {/* <button onClick={() => handleDeleteTag(index)} className="ml-1" >&#10005;</button> */}
                                        </div>
                                    ))}

                                    <input data-testid='tags'
                                        id="tags"
                                        className={`outline-none p-2 ${lotData.tags.length==0 ? 'w-[100px]' : (lotData.tags.length==3 ? 'w-[30px]' : 'w-[40%]')}`}
                                        type="text"
                                        placeholder={lotData.tags.length==0 && "Max 3 tags"}
                                        value={inputValue}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleTagChange}
                                    />

                                </div>
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}